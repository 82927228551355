import { useResourceReservationRequestInfoForProduct } from "@@/resource-reservations/state/resource-reservation-info/use-resource-reservation-info-for-product";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { browserLogger } from "@@/settings";
import {
    BookableSession,
    calculateAvailabilitySpecialized,
    generateResourceTimeRanges,
    getBookableResourceReferences,
    getOverrideResource,
    getSessionMinTimeRange,
    ResourceId,
    TimeRange,
} from "@towni/common";
import { useCallback, useMemo } from "react";
import { useBookingContext } from "../booking-context";
import { SessionResourceAvailability } from "./session-resource-availability";
import { useRequestedQuantities } from "./use-requested-quantities";

/**
 * Checks for resource availability for a given session.
 * @return {*}
 */
const useCheckBookableSession = () => {
    const state = useBookingContext(state => {
        return {
            sku: state.sku,
            session: state.session,
            bookable: state.bookable,
            product: state.product,
            sessions: state.sessions,
            isOnPremiseBooking: state.isOnPremiseBooking,
            allowBookingIsSplit: state.allowBookingIsSplit,
            requestedResources: state.requestedResources,
            ignoreOrder: state.ignoreOrder,
        };
    });
    const requestedQuantities = useRequestedQuantities();
    const skuReferences = getBookableResourceReferences(state.bookable);

    const [globalResources, { isPending: isPendingGlobalResources }] =
        useBookableResources(state.sku.productId);

    const [
        resourceReservationInfo,
        { isPending: isPendingResourceReservationInfo },
    ] = useResourceReservationRequestInfoForProduct(
        state.product._id,
        state.ignoreOrder,
    );

    const maxGuestsPerSession =
        state.bookable?.type === "TABLE_BOOKING"
            ? state.bookable.maxGuestsPerSession
            : undefined;
    const checkSession = useCallback(
        (
            session: BookableSession,
            timeRange?: TimeRange,
            overrideSession?: boolean, //Override session availability, PreparationTime, used by on premise booking
        ) => {
            const localResources = skuReferences.map(item => {
                return getOverrideResource(session, item);
            });

            //Translate to local resources if a sessions has override
            const requestedQuantitiesLocal = skuReferences
                .map(ref => {
                    return [
                        getOverrideResource(session, ref)._id,
                        requestedQuantities.get(ref.resource._id) ?? 0,
                    ] as const;
                })
                .reduce((agg, item) => {
                    const current = agg.get(item[0]) ?? 0;
                    agg.set(item[0], current + item[1]);
                    return agg;
                }, new Map<ResourceId, number>());

            const sessionTimeRange =
                timeRange ?? getSessionMinTimeRange(session);

            const specialized = skuReferences.flatMap(current => {
                const times = generateResourceTimeRanges(
                    sessionTimeRange,
                    current,
                    session,
                );
                return times;
            });

            const resourceAvailability = calculateAvailabilitySpecialized(
                localResources,
                requestedQuantitiesLocal,
                globalResources,
                specialized,
                resourceReservationInfo,
                session.bookableSettings, // ?? state.bookable.settings,
                {
                    allowSplitBookings: state.allowBookingIsSplit,
                    requestedResources: state.requestedResources,
                    maxGuestPerTime: maxGuestsPerSession,
                    limitTableToClosest: skuReferences.some(
                        f => f.resourcePickerEnabled === "LIMIT_FOR_CUSTOMER",
                    ),
                    sessionId: session._id,
                    overrideSession: overrideSession,
                },
            );
            browserLogger.info("resourceAvailability", {
                session,
                resourceAvailability,
                specialized,
                timeRange,
                sessionTimeRange,
            });
            const result: SessionResourceAvailability = {
                bookableSession: session,
                resourceAvailabilities: resourceAvailability,
            };

            return result;
        },
        [
            skuReferences,
            globalResources,
            resourceReservationInfo,
            state.allowBookingIsSplit,
            state.requestedResources,
            maxGuestsPerSession,
            requestedQuantities,
        ],
    );

    const isPending =
        isPendingGlobalResources || isPendingResourceReservationInfo;

    return useMemo(
        () => [checkSession, isPending] as const,
        [checkSession, isPending],
    );
};

export { useCheckBookableSession };
