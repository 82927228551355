import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { browserLogger } from "@@/settings";
import { DateSelectionVtRange } from "@@/shared/date-time/date-time-selection/dates/date-selection-vt";
import {
    AccommodationFeed,
    IsoAndUnixTimestamp,
    TimeRange,
    adjustTimeForAccommodation,
    asDate,
    datesBetweenIso,
    inDays,
    isIsoAndUnix,
    isTimeRange,
    isoAndUnixFactory,
    timeRangeFactory,
} from "@towni/common";
import { addDays, addMonths, startOfToday, startOfYesterday } from "date-fns";
import { useMemo } from "react";

const selectableDatesForAccommodation = (
    start: IsoAndUnixTimestamp,
    minimumStay: number,
    maximumStay: number,
) => {
    const dates = datesBetweenIso(
        isoAndUnixFactory(addDays(asDate(start), minimumStay)),
        isoAndUnixFactory(addDays(asDate(start), maximumStay)),
    );
    return dates;
};

const AccommodationDatePicker = () => {
    const {
        selectedTime,
        feed,
        setSelectedTime: setTimeRange,
        cutoffDate,
    } = useBookingAccommodationContext(state => ({
        selectedTime: state.timeRange,
        setSelectedTime: state.setTime,
        feed: state.feed,
        cutoffDate: state.cutoffDate,
    }));

    const selectableDates = useMemo(() => {
        return feed.dates

            .filter(d => {
                if (
                    isIsoAndUnix(selectedTime) &&
                    selectedTime.unix < d.date.unix
                ) {
                    return checkIfRangeIsBookable(
                        timeRangeFactory(selectedTime, d.date),
                        feed,
                    );
                }
                return d.settings.canCheckIn;
            })

            .map(r => r.date)
            .filter(d => cutoffDate.unix <= d.unix);
    }, [feed, selectedTime, cutoffDate.unix]);

    const selectableDatesForTimeRange = useMemo(() => {
        if (isIsoAndUnix(selectedTime)) {
            const partFeed = feed.dates.find(
                d => d.date.unix === selectedTime.unix,
            );

            if (partFeed) {
                const dates = selectableDatesForAccommodation(
                    selectedTime,
                    partFeed.settings.minimumStay,
                    partFeed.settings.maximumStay,
                );

                return dates;
            }
        }

        return [];
    }, [feed.dates, selectedTime]);

    const selectionTimeRange = useMemo(() => {
        if (selectableDates.length === 0) {
            const in3Months = addMonths(startOfToday(), 3);
            return timeRangeFactory(startOfYesterday(), in3Months);
        }
        const start = selectableDates[0];
        const end = selectableDates[selectableDates.length - 1];

        return timeRangeFactory(start, end);
    }, [selectableDates]);

    browserLogger.info({
        feed,
    });

    return (
        <DateSelectionVtRange
            initiallySelected={
                isTimeRange(selectedTime) ? selectedTime : undefined
            }
            timeRange={selectionTimeRange}
            onSelect={time => {
                if (isTimeRange(time)) {
                    const partFeed = getPartFeed(feed, time);
                    if (partFeed.length <= 1) return;

                    //Use its settings
                    const firstDay = partFeed[0];

                    setTimeRange(
                        adjustTimeForAccommodation(
                            time,
                            firstDay.settings.checkInTime,
                            firstDay.settings.checkOutTime,
                        ),
                    );
                } else {
                    if (time) {
                        // Test to see if we can auto set the end date
                        // const partFeed = feed.dates.find(
                        //     d => d.date.unix === time.unix,
                        // );

                        // if (partFeed) {
                        //     const dates = selectableDatesForAccommodation(
                        //         time,
                        //         partFeed.settings.minimumStay,
                        //         partFeed.settings.maximumStay,
                        //     );
                        //     browserLogger.info({
                        //         dates,
                        //     });
                        //     if (dates.length === 1) {
                        //         const lastDay = dates[0];

                        //         setTimeRange(
                        //             adjustTimeForAccommodation(
                        //                 timeRangeFactory(time, lastDay),
                        //                 partFeed.settings.checkInTime,
                        //                 partFeed.settings.checkOutTime,
                        //             ),
                        //         );
                        //     } else {
                        //         setTimeRange(time);
                        //     }
                        // }

                        setTimeRange(time);
                    } else {
                        setTimeRange(time);
                    }
                }
            }}
            validate={_range => {
                const val = checkIfRangeIsBookable(_range, feed);

                return val;
            }}
            selectableDates={selectableDates}
            selectableDatesForTimeRange={selectableDatesForTimeRange}
            bookedDates={[]}
            cutoffDate={cutoffDate}
        />
    );
};

const getPartFeed = (feed: AccommodationFeed, timeRange: TimeRange) => {
    const partFeed = feed.dates.filter(
        d =>
            d.date.unix >= timeRange.start.unix &&
            d.date.unix <= timeRange.end.unix,
    );
    return partFeed;
};
// This is a helper function to check if a range is bookable based on feed
const checkIfRangeIsBookable = (_range: TimeRange, feed: AccommodationFeed) => {
    const days = inDays({ seconds: _range.duration });

    const partFeed = getPartFeed(feed, _range);
    if (partFeed.length <= 1) return false;

    //Use its settings
    const firstDay = partFeed[0];

    const lastDay = partFeed[partFeed.length - 1];
    const allDaysExceptLast = partFeed.slice(0, -1);

    //Check if the first and last day is bookable
    if (!firstDay.settings.canCheckIn) return false;
    if (!lastDay.settings.canCheckOut) return false;

    if (days < firstDay.settings.minimumStay) return false;
    if (days > firstDay.settings.maximumStay) return false;

    // browserLogger.debug("Checking if range is bookable", {
    //     partFeed,
    //     _range,
    // });

    if (allDaysExceptLast.some(d => !d.isAvailable)) return false;

    //Todo check if same resource is available all days in the range

    return true;
};

export { AccommodationDatePicker };
