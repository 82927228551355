import { BookableExtraOrderItemRow } from "@@/products/bookables/bookable-extra-order-item-row";
import { useBookingContext } from "@@/products/bookables/booking-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { SerializedStyles, css, useTheme } from "@emotion/react";
import {
    ExtrasGroup,
    ProductId,
    Translatable,
    emptyArrayOf,
    getChildOptionals,
    isOrderItemBuyable_V2,
    translation,
} from "@towni/common";
import { useMemo } from "react";
import { useCart } from "../multi-carts/cart.context";

const BookableExtrasPanel = () => {
    const theme = useTheme();
    const product = useBookingContext(context => context.product);
    const bookable = useBookingContext(context => context.bookable);
    const cart = useCart();
    const extras = cart.orderItems.filter(o => o.acquireType === "BUYABLE");
    const extrasGroup = bookable.extrasGroups ?? emptyArrayOf<ExtrasGroup>();

    if (extras.length === 0) return null;

    return (
        <FlexColumn>
            <VerticalDivider M />
            <Conditional when={extrasGroup.length > 1}>
                <FlexRow padding={{ leftRight: 20 }} crossAxis="center">
                    <TextBox
                        size={1.25}
                        weight="800"
                        text={translation({
                            sv: "Tillval",
                            en: "Extras",
                        })}
                    />
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>

            <ExtrasOrderItemsV2
                extras={extrasGroup}
                productIdsToIgnore={[product._id]}
                disableActions={true}
                extraSubTitleStyle={css`
                    color: ${theme.colors.black.light50.asString};
                    font-weight: 400;
                `}
            />
        </FlexColumn>
    );
};

type EProps = {
    extras: ExtrasGroup[];
    productIdsToIgnore: ProductId[];
    title?: Translatable;
    canBeRemoved?: boolean;
    disableActions?: boolean;
    extraSubTitleStyle?: SerializedStyles;
};
const ExtrasOrderItemsV2 = (props: EProps) => {
    const cart = useCart();
    const orderItems = useMemo(
        () =>
            cart.orderItems.filter(
                item =>
                    isOrderItemBuyable_V2(item) &&
                    !props.productIdsToIgnore?.includes(item.productId),
            ),
        [cart.orderItems, cart],
    );
    if (orderItems.length === 0) return null;

    return (
        <FlexColumn fillParentWidth padding={{ all: 20, top: 10 }}>
            <Conditional
                when={!!props.title}
                render={() => (
                    <FlexColumn fillParentWidth crossAxis="center">
                        <VerticalDivider S />
                        <TextBox
                            size={"ML"}
                            weight="700"
                            text={translation({
                                sv: "Tillagda tillval",
                                en: "Added extras",
                            })}
                        />
                        <VerticalDivider S />
                    </FlexColumn>
                )}
            />
            <ForEach
                itemOf={props.extras}
                getKey={e => e._id}
                divider={<VerticalDivider L />}>
                {(extra, _index) => {
                    const extraItems = orderItems.filter(
                        oi => oi.extraGroup?._id === extra._id,
                    );

                    if (extraItems.length === 0) return null;

                    return (
                        <>
                            <TextBox
                                weight="700"
                                text={extra.meta.title}
                                styling={props.extraSubTitleStyle}
                            />
                            <VerticalDivider S />
                            <ForEach
                                itemOf={extraItems}
                                getKey={oi => oi._id}
                                divider={<VerticalDivider />}>
                                {(orderItem, _index) => {
                                    return (
                                        <BookableExtraOrderItemRow
                                            orderItem={orderItem}
                                            picked={getChildOptionals(
                                                orderItem,
                                                cart.orderItems,
                                            )}
                                            disableActions={
                                                props.disableActions
                                            }
                                        />
                                    );
                                }}
                            </ForEach>
                        </>
                    );
                }}
            </ForEach>
        </FlexColumn>
    );
};

export { BookableExtrasPanel };
