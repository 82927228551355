import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useDefaultReactSelectStyles } from "@@/shared/form/use-default-react-select-styles";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { DynamicIcon, type IconLibrary } from "@@/shared/icons/dynamic-icon";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { IconName, Padding, Translatable } from "@towni/common";
import { Draft } from "immer";
import { CSSProperties, useState } from "react";
import ReactSelect, {
    MultiValueGenericProps,
    OptionProps,
    SingleValueProps,
} from "react-select";
import { ZodSchema } from "zod";

type PossibleValueTypes = string | undefined | null;
type DefaultValueType = string | undefined;

type CustomOption = {
    label: Translatable;
    value: string;
    iconName?: IconName;
    iconLibrary?: IconLibrary;
    iconColor?: string;
};

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (
        draft: Draft<Partial<State>>,
        newValue: Value | undefined,
    ) => void;

    readonly fieldSchema?: ZodSchema;

    readonly options: CustomOption[];

    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly padding?: Padding;
    readonly width?: CSSProperties["width"];
};

const createFormSelectComponent = <State extends Record<string, unknown>>() => {
    const Form2Select = <Value extends PossibleValueTypes = DefaultValueType>(
        props: Props<State, Value>,
    ) => {
        const translate = useTranslate();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value | undefined>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            formId: props.formId,
            fieldSchema: props.fieldSchema,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const [_initialValue] = useState(field.value);
        const hasErrors = field.errors.length > 0;

        const validationTrigger = useFormFieldValidation<
            State,
            Value | undefined
        >({
            field,
            initialValidationType: "manual",
        });

        const translatedOptions = props.options.map((r): CustomOption => {
            return {
                ...r,
                label: translate(r.label),
                value: r.value,
            };
        });
        const hasIcon = props.options.some(r => !!r.iconName);
        const reactSelectStyles = useDefaultReactSelectStyles<
            (typeof translatedOptions)[0],
            false
        >(hasErrors || props.options.length === 0);

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                tag={props.fieldId}
                crossAxis="stretch">
                <Conditional when={!!props.label}>
                    <FlexRow
                        crossAxis="center"
                        mainAxis="space-between"
                        css={{
                            flexShrink: 0,
                        }}>
                        <FieldTitle
                            htmlFor={props.fieldId}
                            padding={{ left: 2 }}
                            required={field.isRequired}
                            text={props.label ?? ""} // already checked with conditional above
                        />
                        <Conditional when={!!props.labelDescription}>
                            <HorizontalDivider XXS />
                            <FieldTitle
                                padding={{ left: 2 }}
                                text={props.labelDescription ?? ""} // already checked with conditional above
                                weight="400"
                                size="S"
                                css={{
                                    opacity: 0.5,
                                }}
                            />
                        </Conditional>
                    </FlexRow>
                    <VerticalDivider XS />
                </Conditional>
                <ReactSelect
                    options={translatedOptions}
                    value={translatedOptions.find(
                        item => item.value === field.value,
                    )}
                    onBlur={() => {
                        field.setTouched(true);
                        validationTrigger();
                    }}
                    menuPortalTarget={dropDownPortalTarget}
                    menuPlacement="auto"
                    isDisabled={props.disabled}
                    styles={reactSelectStyles}
                    css={{
                        width: props.width,
                    }}
                    components={
                        hasIcon
                            ? {
                                  Option: OptionWithIcon,
                                  SingleValue: SingleValueWithIcon,
                                  MultiValueLabel: MultiValueLabelWithIcon,
                              }
                            : undefined
                    }
                    onChange={option => {
                        const optionValue = option?.value;
                        const newValue: NonNullable<Value> | undefined =
                            optionValue === null
                                ? undefined
                                : (option?.value as NonNullable<Value>);
                        field.setValue(newValue);
                        field.setDirty(newValue !== _initialValue);
                        field.setTouched(true);
                        validationTrigger();
                    }}
                />
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        marginTop: 5,
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2Select;
};

const OptionWithIcon = (props: OptionProps<CustomOption>) => {
    const theme = useTheme();
    return (
        <div ref={props.innerRef} {...props.innerProps}>
            {/* {isFirst ? <VerticalDivider XXS /> : null} */}
            <FlexRow
                crossAxis="center"
                padding={{
                    top: 12,
                    bottom: 12,
                    leftRight: 15,
                }}
                css={{
                    color: (() => {
                        if (props.isFocused)
                            return theme.colors.primary.text.asString;
                        return theme.colors.default.text.asString;
                    })(),
                    backgroundColor: (() => {
                        if (props.isFocused)
                            return theme.colors.primary.asString;
                        if (props.isSelected)
                            return theme.colors.primary.light.asString;
                        return theme.colors.default.background.asString;
                    })(),
                }}>
                <DynamicIcon
                    icon={props.data.iconName}
                    size={1}
                    fixedWidth
                    transparentWhenNoIcon
                    library={props.data.iconLibrary}
                    css={{
                        color:
                            props.data.iconName && props.isFocused
                                ? theme.colors.default.background.asString
                                : props.data.iconColor,
                    }}
                />
                <HorizontalDivider S />
                <TextBox text={props.data.label} />
            </FlexRow>
            {/* {isLast ? <VerticalDivider XXS /> : null} */}
        </div>
    );
};
const SingleValueWithIcon = (props: SingleValueProps<CustomOption>) => {
    return (
        <div
            {...props.innerProps}
            css={{
                position: "absolute",
                top: 0,
                left: 0,
            }}>
            {/* {isFirst ? <VerticalDivider XXS /> : null} */}
            <FlexRow
                crossAxis="center"
                padding={{
                    top: 12,
                    bottom: 12,
                    leftRight: 15,
                }}>
                <Conditional when={!!props.data.iconName?.trim()}>
                    <DynamicIcon
                        icon={props.data.iconName}
                        size={1}
                        fixedWidth
                        library={props.data.iconLibrary}
                        css={{
                            color: props.data.iconColor,
                        }}
                    />
                    <HorizontalDivider S />
                </Conditional>
                <TextBox text={props.data.label} />
            </FlexRow>
            {/* {isLast ? <VerticalDivider XXS /> : null} */}
        </div>
    );
};
const MultiValueLabelWithIcon = (
    props: MultiValueGenericProps<CustomOption>,
) => {
    return (
        <div {...props.innerProps}>
            {/* {isFirst ? <VerticalDivider XXS /> : null} */}
            <FlexRow crossAxis="center">
                <DynamicIcon
                    icon={props.data.iconName}
                    fixedWidth
                    transparentWhenNoIcon
                    library={props.data.iconLibrary}
                    css={{
                        color: props.data.iconColor,
                    }}
                />
                <HorizontalDivider XS />
                <TextBox text={props.data.label} />
            </FlexRow>
            {/* {isLast ? <VerticalDivider XXS /> : null} */}
        </div>
    );
};

export { createFormSelectComponent };
export type {
    CustomOption as CustomSelectOption,
    PossibleValueTypes as Form2SelectPossibleValueTypes,
    Props as Form2SelectProps,
};
