import { PageLoad } from "@@/pages/page-load";
import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { useAvailableResourcesForProductAndSession } from "@@/products/bookables/booking-wizard/bookable-resource-map-picker/use-available-resources-for-product-and-session";
import { useBookableResources } from "@@/resources/resources/use-resources";

import {
    blackeningGradient20,
    whiteningGradient20,
} from "@@/shared/bg-lightening-gradient";
import { BackButton } from "@@/shared/buttons/back-button";
import { useCurrentBookablePrice } from "@@/shared/buttons/bookables/use-current-bookable-price";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useFindHtmlElementForResourceId } from "@@/shared/resource-maps/use-html-element-resource-map-selection";
import { ZoomableResourceMapView } from "@@/shared/resource-maps/zoomable-resource-map-view";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { usePrevious } from "@@/shared/use-previous";
import { useUnitTranslator } from "@@/translations/use-unit-translator";
import { useTheme } from "@emotion/react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import {
    Percentage,
    ResourceGraph,
    emptyArrayOf,
    findGraphInGraph,
    findParentGraphOfChildInGraph,
    getBookableResourceReferences,
    isNotResourceGroup,
    isResourceGroup,
    isTableBooking,
    sum,
    translation,
} from "@towni/common";
import { useEffect, useMemo, useRef, useState } from "react";
import type { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { useBookingContext } from "../../booking-context";
import { useAddBookableToCartOrGoNext } from "../../hooks/use-add-bookable-to-cart";
import { useCheckReservationFromBookingContext } from "../../hooks/use-create-reservation";
import { getRReferencesUnit, useTotalQuantityUnit } from "../../resource-pill";
import { ResourcePickerList } from "./resource-picker-list";

const ResourcePickerPage = () => {
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        hasAvailableExtrasOrOptionals: context.hasAvailableExtrasOrOptionals,
    }));

    const wizardActions = useWizardDispatch();
    const _wizardState = useWizardState();
    const goNextOrAdd = useAddBookableToCartOrGoNext();

    const [, bookableResourceQuery] = useBookableResources(state.product._id);
    const isLoadingProductResources = bookableResourceQuery.isPending;
    const productResources =
        bookableResourceQuery.data ?? emptyArrayOf<ResourceGraph>();

    const goNext = () => {
        //const nextPageStep = state.hasAvailableExtrasOrOptionals ? 1 : 2;
        //  wizardActions.goForward();
        goNextOrAdd();
    };
    /** Resource references (antalgrupper) with resource picker setting enabled */
    const resourceReferences = getBookableResourceReferences(
        state.bookable,
    ).filter(item => item.resourcePickerEnabled);
    if (resourceReferences.length > 1)
        throw new Error(
            "Multiple resource references (antalgrupper) within the same product cannot yet have resourcePickerEnabled true)",
        );
    if (resourceReferences.length === 0) {
        if (_wizardState.latestDirection === "FORWARD") {
            goNext();
        } else wizardActions.goBack();
        return null;
    }
    const rootResourceGroup = resourceReferences[0]?.resource;
    if (
        !isResourceGroup(rootResourceGroup) ||
        resourceReferences.length === 0
    ) {
        const nextPageStep = state.hasAvailableExtrasOrOptionals ? 1 : 2;
        wizardActions.goForward(nextPageStep);
        return null;
    }
    const rootGraph = productResources.find(
        item => item._id === rootResourceGroup?._id,
    );
    if (isLoadingProductResources) {
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar resurser",
                    en: "Loading resources",
                })}
            />
        );
    }
    if (!rootGraph)
        throw new Error(
            "The root resource reference (antalgrupp) must have a resource graph",
        );
    return (
        <_ResourcePickerPageInternal goNext={goNext} rootGraph={rootGraph} />
    );
};

type Props = {
    rootGraph: ResourceGraph;
    goNext: () => void;
};

const traverseToFindBestStart = (rootGraph: ResourceGraph): ResourceGraph => {
    //Go deeper if there is no choice
    if (
        rootGraph.children.length === 1 &&
        rootGraph.children[0]._type === "RESOURCE_GRAPH"
    ) {
        const next = rootGraph.children[0];
        if (next._type === "RESOURCE_GRAPH" && next.children.length > 0)
            return traverseToFindBestStart(next);
    }

    return rootGraph;
};

const _ResourcePickerPageInternal = ({ rootGraph, goNext }: Props) => {
    const theme = useTheme();
    const zoomableResourceMapViewRef = useRef<ReactZoomPanPinchRef>(null);
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        optionValues: context.optionValues,
        isOnPremiseBooking: context.isOnPremiseBooking,
        requestedResources: context.requestedResources,
        timeRange: context.timeRange,
        session: context.session,
        sku: context.sku,
        settings: context.bookable.settings,
        selectResource: context.selectResource,
        deselectResource: context.deselectResource,
    }));

    const [availableResourcesMap] = useAvailableResourcesForProductAndSession();

    const wizardActions = useWizardDispatch();

    const currentPrice = useCurrentBookablePrice();
    const checkReservation = useCheckReservationFromBookingContext(
        state.product,
        true,
    );

    const actions = useBookingContext(context => ({
        selectResource: context.selectResource,
        deselectResource: context.deselectResource,
    }));

    const [currentGraph, setCurrentGraph] = useState<ResourceGraph>(() => {
        //Try to find the last selected graph
        if (state.requestedResources.length > 0) {
            const lastSelected = findParentGraphOfChildInGraph(
                rootGraph,
                state.requestedResources[state.requestedResources.length - 1],
            );

            if (lastSelected) {
                return lastSelected;
            }
        }

        return traverseToFindBestStart(rootGraph);
    });
    const totalQuantity = useTotalQuantityUnit(state.bookable.main);
    const unitTranslate = useUnitTranslator();
    const unit = getRReferencesUnit(state.bookable.main);
    const unitString = unitTranslate(totalQuantity, unit, "long");
    const selectedQuantity = state.requestedResources.length;
    const selectableQuantity = state.isOnPremiseBooking
        ? totalQuantity
        : isTableBooking(state.bookable.type)
          ? 1
          : totalQuantity;

    const [selectionComplete, missingQuantity] = useMemo(() => {
        if (!state.session) return [false, 0];
        if (!state.timeRange) return [false, 0];
        if (selectedQuantity > selectableQuantity) return [false, 0];

        const reservations = checkReservation({
            session: state.session,
            optionValues: state.optionValues,
            requestedResources: state.requestedResources,
            timeRange: state.timeRange,
            settings: state.settings,
            sku: state.sku,
        });

        return [
            reservations.length === 0,
            sum(reservations, r => r.missingQuantity),
        ];
    }, [
        selectedQuantity,
        selectableQuantity,
        state.session,
        state.timeRange,
        state.optionValues,
        state.requestedResources,
        state.settings,
        state.sku,
        checkReservation,
    ]);

    // Resources within the above resource references
    const resourceMapConnection =
        currentGraph.resourceGroup?.resourceMapConnection;

    const findElementForResourceId = useFindHtmlElementForResourceId(
        resourceMapConnection,
    );
    const previousRequestedResources = usePrevious(state.requestedResources);
    useEffect(() => {
        const additions =
            state.requestedResources.filter(
                item => !previousRequestedResources?.includes(item),
            ) ?? [];
        const subtractions =
            previousRequestedResources?.filter(
                item => !state.requestedResources?.includes(item),
            ) ?? [];
        if (subtractions.length) {
            // zoom out
            // if (zoomableResourceMapViewRef.current) {
            //     zoomableResourceMapViewRef.current.resetTransform();
            // }
        }
        if (additions.length) {
            if (zoomableResourceMapViewRef.current) {
                // zoom in on element if we can find it
                const element = findElementForResourceId(additions[0]);
                if (element) {
                    // the elements in the svg doesn't seem to have an offsetWidth and offsetHeight
                    // which seems to be required to to this, check on it later.
                    // just zoom out instead for now
                    // zoomableResourceMapViewRef.current.zoomToElement(element);
                    // zoomableResourceMapViewRef.current.zoomToElement(element);
                    // zoomableResourceMapViewRef.current.r                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    esetTransform();
                    return;
                }

                // else just reset zoom to initial values
                // zoomableResourceMapViewRef.current.resetTransform();
            }
        }
    }, [state.requestedResources]);

    const availableResources = useMemo(
        () =>
            (
                availableResourcesMap.get(currentGraph.resourceGroup._id) ?? []
            ).filter(isNotResourceGroup),
        [availableResourcesMap, currentGraph.resourceGroup._id],
    );
    const availableResourceIds = useMemo(() => {
        return availableResources.map(item => item._id);
    }, [availableResources]);
    const possibleResourceIds = useMemo(() => {
        return new Set(availableResourceIds);
    }, [availableResourceIds]);

    return (
        <FlexColumn
            fillParent
            mainAxis="stretch"
            crossAxis="stretch"
            css={{
                position: "relative",
                backgroundColor: theme.colors.default.background.asString,
                label: "resource-picker-page",
            }}>
            <div
                css={{
                    position: "absolute",
                    top: 20,
                    left: 20,
                    zIndex: 100,
                }}>
                <BackButton
                    icon={faChevronLeft}
                    size={43}
                    fontSizeRatio={0.4 as Percentage}
                    backgroundColor={theme.colors.primary.light}
                    textColor={theme.colors.primary}
                    onClick={wizardActions.goBack}
                />
            </div>
            <Conditional
                when={!!resourceMapConnection}
                render={() => (
                    <FlexColumn
                        fillParentWidth
                        tag="map"
                        css={{
                            width: "100%",
                            position: "relative",
                            label: "map",
                            flex: 1.2,
                            maxHeight: "50%",
                        }}>
                        <div
                            css={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 1,
                                overflow: "hidden",
                            }}>
                            <ZoomableResourceMapView
                                ref={zoomableResourceMapViewRef}
                                possibleResourceIds={possibleResourceIds}
                                resourceMapConnection={
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    resourceMapConnection!
                                }
                                maxSelect={selectableQuantity}
                                requestedResources={state.requestedResources}
                                selectResource={actions.selectResource}
                                deselectResource={actions.deselectResource}
                            />
                        </div>
                        <div
                            css={{
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 100,
                                pointerEvents: "none",
                                label: "continue_container",
                            }}>
                            <FlexColumn fillParentWidth>
                                <FlexRow
                                    fillParentWidth
                                    mainAxis="flex-end"
                                    background={{
                                        linearGradient: theme.isLightTheme
                                            ? whiteningGradient20
                                            : blackeningGradient20,
                                    }}
                                    padding={{ all: 20, top: 40 }}
                                    crossAxis="center">
                                    <FlexColumn
                                        crossAxis="flex-end"
                                        mainAxis="flex-end">
                                        <Conditional
                                            when={selectionComplete}
                                            else={() => (
                                                <TextBox
                                                    text={
                                                        isTableBooking(
                                                            state.bookable.type,
                                                        )
                                                            ? translation({
                                                                  sv:
                                                                      missingQuantity ===
                                                                      1
                                                                          ? `Saknar en plats`
                                                                          : `Saknar ${missingQuantity} platser`,
                                                                  en:
                                                                      missingQuantity ===
                                                                      1
                                                                          ? `Missing one seat`
                                                                          : `Missing ${missingQuantity} seats`,
                                                              })
                                                            : translation({
                                                                  sv: `Saknar ${missingQuantity} st`,
                                                                  en: `Missing ${missingQuantity} pcs`,
                                                              })
                                                    }
                                                    color={theme.colors.primary}
                                                    size={0.875}
                                                    weight="700"
                                                />
                                            )}>
                                            <PriceTextBox
                                                price={currentPrice(undefined)}
                                                color={theme.colors.primary}
                                                weight="700"
                                            />
                                        </Conditional>

                                        <FlexRow
                                            fillParentWidth
                                            mainAxis="center">
                                            <TextBox
                                                text={totalQuantity.toString()}
                                                size={0.875}
                                                color={theme.colors.primary}
                                            />
                                            <HorizontalDivider XS />
                                            <TextBox
                                                text={unitString}
                                                size={0.875}
                                                color={theme.colors.primary}
                                            />
                                        </FlexRow>
                                    </FlexColumn>
                                    <HorizontalDivider M />
                                    <ButtonPrimary
                                        radius={3000}
                                        css={{
                                            pointerEvents: "all",
                                            label: "continue_button",
                                        }}
                                        onClick={() => {
                                            goNext();
                                        }}
                                        disabled={!selectionComplete}>
                                        <TextBox
                                            padding={{
                                                leftRight: 15,
                                                topBottom: 2,
                                            }}
                                            text={translation({
                                                sv: "Välj »",
                                                en: "Select »",
                                            })}
                                        />
                                    </ButtonPrimary>
                                </FlexRow>
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                )}
                else={() => (
                    <>
                        <FlexRow
                            fillParentWidth
                            mainAxis="center"
                            css={{
                                height: 80,
                                backgroundColor:
                                    theme.colors.default.background.asString,
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Välj i listan",
                                    en: "Select in list",
                                })}
                                size={1.125}
                                weight="900"
                                color={theme.colors.black.light65}
                            />
                        </FlexRow>
                        <FlexRow
                            fillParentWidth
                            mainAxis="flex-end"
                            padding={{ all: 20 }}
                            crossAxis="center">
                            <FlexColumn crossAxis="flex-end">
                                <PriceTextBox
                                    price={currentPrice(undefined)}
                                    color={theme.colors.primary}
                                    weight="700"
                                />
                                <FlexRow fillParentWidth mainAxis="center">
                                    <TextBox
                                        text={totalQuantity.toString()}
                                        size={0.875}
                                        color={theme.colors.primary}
                                    />
                                    <HorizontalDivider XS />
                                    <TextBox
                                        text={unitString}
                                        size={0.875}
                                        color={theme.colors.primary}
                                    />
                                </FlexRow>
                            </FlexColumn>
                            <HorizontalDivider M />
                            <ButtonPrimary
                                radius={3000}
                                onClick={() => goNext()}
                                disabled={!selectionComplete}>
                                <TextBox
                                    padding={{
                                        leftRight: 15,
                                        topBottom: 2,
                                    }}
                                    text={translation({
                                        sv: "Välj »",
                                        en: "Select »",
                                    })}
                                />
                            </ButtonPrimary>
                        </FlexRow>
                    </>
                )}
            />
            <FlexColumn
                fillParentWidth
                css={{
                    height: "min-content",
                    maxHeight: resourceMapConnection ? "50%" : "100%",
                }}>
                <ResourcePickerList
                    graph={currentGraph}
                    maxSelect={selectableQuantity}
                    // availableResourcesMap={availableResourcesMap}
                    possibleResourceIds={possibleResourceIds}
                    selectResource={state.selectResource}
                    deselectResource={state.deselectResource}
                    requestedResources={state.requestedResources}
                    goNext={goNext}
                    onGraphClick={setCurrentGraph}
                    goBack={
                        currentGraph.parentId
                            ? () => {
                                  setCurrentGraph(
                                      old =>
                                          findGraphInGraph(
                                              rootGraph,
                                              old.parentId,
                                          ) ?? rootGraph,
                                  );
                              }
                            : undefined
                    }
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export { ResourcePickerPage };
