import { CartProviderCheckModal } from "@@/providers/cart-provider-check-modal";
import { ProviderPauseCheckModal } from "@@/providers/provider-pause-check-modal";
import { browserLogger } from "@@/settings";
import { ConfirmTimeSlot } from "@@/shared/buttons/bookables/confirm-time-slot";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { Product, Provider } from "@towni/common";
import { AnimatePresence } from "framer-motion";
import * as React from "react";
import { useBookingContext } from "../../booking-context";
import { useBookingForSessions } from "../../hooks/use-booking-for-sessions";
import { useCheckBookableSession } from "../../hooks/use-check-bookable-session";
import { ResourcePill } from "../../resource-pill";
import { BookableTimePickerNothingAvailable } from "./bookable-time-picker-nothing-available";
import { BookableSessionSelect } from "./pickers/bookable-session-select";

type Props = {
    readonly product: Product;
    readonly provider: Provider;
};

const BookableTimePicker = (props: Props) => {
    const { provider, product } = props;
    const theme = useTheme();
    const state = useBookingContext(state => ({
        bookable: state.bookable,
        session: state.session,
        optionValues: state.optionValues,
        isLoadingSessions: state.isLoadingSessions,
        isOnPremiseBooking: state.isOnPremiseBooking,
    }));
    const actions = useBookingContext(state => ({
        clearSession: state.clearSession,
    }));
    const toast = useToast();

    const [sessionAvailability, isLoading] = useBookingForSessions();

    const resourceRefs = React.useMemo(
        () => [state.bookable.main, ...state.bookable.resources],
        [state.bookable],
    );
    const [checkAvailability, checkSessionIsLoading] =
        useCheckBookableSession();

    React.useEffect(() => {
        //Check if a choosen session is still valid
        if (
            sessionAvailability.length > 0 &&
            state.session &&
            !checkSessionIsLoading
        ) {
            const availability = checkAvailability(
                state.session,
                undefined,
                state.isOnPremiseBooking ? true : undefined,
            );

            const isBookable = !availability.resourceAvailabilities.some(
                a => !a.available,
            );
            if (!isBookable) {
                toast.info({
                    message:
                        "Den valda tiden är inte tillgänglig, se andra tider.",
                });
                // Time not available
                actions.clearSession();
            }
        }
    }, [
        state.session,
        state.optionValues,
        sessionAvailability,
        checkAvailability,
        checkSessionIsLoading,
        toast,
        actions,
        state.isOnPremiseBooking,
    ]);

    browserLogger.info("BookableTimePicker", {
        sessionAvailability,
        isLoading,
        resourceRefs,
        checkSessionIsLoading,
    });
    return (
        <>
            <FlexColumn
                fillParent
                css={{
                    flex: 1,
                    label: "bookable_time_picker",
                }}
                background={{ color: theme.colors.default.background }}>
                <FlexColumn
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="stretch">
                    {/* Page Header */}
                    <FlexColumn shrink={0} overflow="hidden" fillParentWidth>
                        <VerticalDivider M />
                        <FlexRow mainAxis="center">
                            <Conditional
                                when={
                                    sessionAvailability.length > 0 || !isLoading
                                }>
                                <ForEach
                                    itemOf={resourceRefs}
                                    getKey={rr => rr._id}>
                                    {resourceRef => (
                                        <ResourcePill
                                            key={resourceRef._id}
                                            product={product}
                                            provider={provider}
                                            resourceRef={resourceRef}
                                        />
                                    )}
                                </ForEach>
                            </Conditional>
                        </FlexRow>
                    </FlexColumn>
                    <VerticalDivider XS />
                </FlexColumn>

                <Conditional
                    when={sessionAvailability.length > 0}
                    spin={state.isLoadingSessions || isLoading}
                    whenLoading={() => (
                        <FlexColumn fillParent>
                            <TextShimmer rows={3} css={{ padding: 20 }} />
                        </FlexColumn>
                    )}
                    render={() => (
                        <BookableSessionSelect
                            sessions={sessionAvailability}
                            ignoreSession={state.isOnPremiseBooking}
                        />
                    )}
                    else={() => <BookableTimePickerNothingAvailable />}
                />
                <VerticalDivider XXL />
                <AnimatePresence>
                    <ConfirmTimeSlot padding={{ all: 15 }} />
                </AnimatePresence>
            </FlexColumn>

            {/* Modals */}
            <CartProviderCheckModal providerId={product.providerId} />
            <ProviderPauseCheckModal providerId={product.providerId} />
        </>
    );
};

export { BookableTimePicker };
