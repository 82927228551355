import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { usePrevious } from "@@/shared/use-previous";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { faClock, faCopy } from "@fortawesome/pro-regular-svg-icons";
import {
    Hour,
    HourMinute,
    Minute,
    Translatable,
    hourMinuteFactory,
    hours,
    minutes,
    translation,
} from "@towni/common";
import copy from "copy-to-clipboard";
import { Draft } from "immer";
import { useCallback, useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { ZodSchema } from "zod";
import { FloatingBox } from "../floating-box";

type Value = HourMinute | undefined;

const TimePickerContainer_FlexRow = FlexRow;
const TimePicker_FlexColumn = FlexColumn;
const HourPicker_FlexColumn = FlexColumn;
const MinutePicker_FlexColumn = FlexColumn;
const ActionRows_FlexRow = FlexRow;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly disabled?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
};

const Form2TimePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const toast = useToast();
    // const locale = useLocale();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const hasErrors = field.errors.length > 0;
    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });

    const [selectedHour, setHour] = useState<Hour | undefined>(
        field.initialValue?.hour,
    );
    const [selectedMinute, setMinute] = useState<Minute | undefined>(
        field.initialValue?.minute,
    );

    useUpdateEffect(() => {
        if (
            typeof selectedHour === "undefined" ||
            typeof selectedMinute === "undefined"
        )
            return;
        field.setValue(hourMinuteFactory(selectedHour, selectedMinute));
        field.setDirty(true);
        field.setTouched(true);
        validationTrigger();
    }, [selectedHour, selectedMinute]);

    const [hoursRendered, setHoursRendered] = useState<boolean>(false);
    const [minutesRendered, setMinutesRendered] = useState<boolean>(false);
    const hoursRefCallback = useCallback((element: HTMLDivElement) => {
        setHoursRendered(!!element);
    }, []);
    const minutesRefCallback = useCallback((element: HTMLDivElement) => {
        setMinutesRendered(!!element);
    }, []);

    const prevHoursRendered = usePrevious(hoursRendered);
    useEffect(
        function scrollHourIntoView() {
            if (!hoursRendered) return;
            const hourToScrollTo = selectedHour ?? (8 as Hour);
            const scrollBehavior = !prevHoursRendered ? "instant" : "smooth";
            const hourElement = document.getElementById(
                `hour_${hourToScrollTo}_${field.fieldId}`,
            );
            hourElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
        },
        [hoursRendered, field.fieldId, selectedHour, prevHoursRendered],
    );

    const prevMinutesRendered = usePrevious(minutesRendered);
    useEffect(
        function scrollMinuteIntoView() {
            if (typeof selectedMinute === "undefined") return;
            if (!minutesRendered) return;
            const scrollBehavior = !prevMinutesRendered ? "instant" : "smooth";
            const minuteElement = document.getElementById(
                `minute_${selectedMinute}_${field.fieldId}`,
            );
            minuteElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
        },
        [field.fieldId, minutesRendered, prevMinutesRendered, selectedMinute],
    );

    const componentKey = `${formId}_${props.fieldId}`;
    useEffect(() => {
        // Handle paste from clipboard
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== componentKey) return;
            const pastedText = (ev as ClipboardEvent).clipboardData?.getData(
                "Text",
            );
            if (!pastedText?.trim()) return;
            const isTimeString = new RegExp(/(\d{1,2}):(\d{1,2})/g).test(
                pastedText,
            );
            if (!isTimeString) return;
            const [hour, minute] = pastedText.split(":").map(Number);
            if (
                !hours.includes(hour as Hour) ||
                !minutes.includes(minute as Minute)
            )
                return;
            setHour(hour as Hour);
            setMinute(minute as Minute);
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [componentKey]);

    return (
        <FlexColumn
            id={componentKey}
            key={componentKey}
            tabIndex={0}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="700"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>

            <FloatingBox
                content={actions => (
                    <TimePickerContainer_FlexRow
                        css={{
                            backgroundColor: "white",
                            borderRadius: 10,
                            padding: 5,
                            willChange: "filter",
                            transition: "opacity 0.2s ease-in-out",
                            filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                        }}>
                        {/* TIDSVÄLJARE */}
                        <TimePicker_FlexColumn
                            css={{
                                minWidth: "max-content",
                            }}>
                            <FlexRow
                                mainAxis="space-evenly"
                                crossAxis="stretch"
                                css={{ flex: 1, position: "relative" }}>
                                <HourPicker_FlexColumn
                                    ref={hoursRefCallback}
                                    css={[
                                        {
                                            flex: 1,
                                            maxWidth: 150,
                                            minHeight: 150,
                                            maxHeight: 200,
                                            overflowX: "hidden",
                                            overflowY: "auto",
                                            borderRightStyle: "solid",
                                            borderRightWidth: 1,
                                            borderRightColor:
                                                theme.colors.default.border
                                                    .asString,
                                        },
                                        customThinScrollbarCss,
                                    ]}>
                                    {/* Timmar */}
                                    <_TimePickerHeader
                                        text={translation({
                                            sv: "Timme",
                                            en: "Hour",
                                        })}
                                    />
                                    <ForEach
                                        itemOf={hours}
                                        getKey={hour => hour}>
                                        {hour => (
                                            <ButtonTransparent
                                                id={`hour_${hour}_${field.fieldId}`}
                                                padding={{
                                                    topBottom: 5,
                                                    right: 15,
                                                    left: 15,
                                                }}
                                                onClick={() => {
                                                    setHour(hour);
                                                }}>
                                                <TextBox
                                                    text={hour
                                                        .toString()
                                                        .padStart(2, "0")}
                                                    padding={{
                                                        leftRight: 15,
                                                        topBottom: 5,
                                                    }}
                                                    weight="700"
                                                    css={{
                                                        backgroundColor:
                                                            hour ===
                                                            selectedHour
                                                                ? theme.colors
                                                                      .primary
                                                                      .light
                                                                      .asString
                                                                : undefined,
                                                        border:
                                                            hour ===
                                                            selectedHour
                                                                ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                                : `1px solid ${theme.colors.transparent.asString}`,

                                                        borderRadius: 5,
                                                        color:
                                                            hour ===
                                                            selectedHour
                                                                ? theme.colors
                                                                      .primary
                                                                      .background
                                                                      .asString
                                                                : undefined,
                                                    }}
                                                />
                                            </ButtonTransparent>
                                        )}
                                    </ForEach>
                                </HourPicker_FlexColumn>
                                <MinutePicker_FlexColumn
                                    ref={minutesRefCallback}
                                    css={[
                                        {
                                            flex: 1,
                                            maxWidth: 150,
                                            minHeight: 150,
                                            maxHeight: 200,
                                            overflowX: "hidden",
                                            overflowY: "auto",
                                        },
                                        customThinScrollbarCss,
                                    ]}>
                                    {/* Minuter */}
                                    <_TimePickerHeader
                                        text={translation({
                                            sv: "Minut",
                                            en: "Minute",
                                        })}
                                    />
                                    <ForEach
                                        itemOf={minutes}
                                        getKey={minute => minute}>
                                        {minute => (
                                            <ButtonTransparent
                                                id={`minute_${minute}_${field.fieldId}`}
                                                padding={{
                                                    topBottom: 5,
                                                    right: 15,
                                                    left: 15,
                                                }}
                                                onClick={() => {
                                                    setMinute(minute);
                                                }}>
                                                <TextBox
                                                    text={minute
                                                        .toString()
                                                        .padStart(2, "0")}
                                                    padding={{
                                                        leftRight: 15,
                                                        topBottom: 5,
                                                    }}
                                                    weight="700"
                                                    css={{
                                                        backgroundColor:
                                                            minute ===
                                                            selectedMinute
                                                                ? theme.colors
                                                                      .primary
                                                                      .light
                                                                      .asString
                                                                : undefined,
                                                        borderRadius: 5,
                                                        border:
                                                            minute ===
                                                            selectedMinute
                                                                ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                                : `1px solid ${theme.colors.transparent.asString}`,
                                                        color:
                                                            minute ===
                                                            selectedMinute
                                                                ? theme.colors
                                                                      .primary
                                                                      .background
                                                                      .asString
                                                                : undefined,
                                                    }}
                                                />
                                            </ButtonTransparent>
                                        )}
                                    </ForEach>
                                </MinutePicker_FlexColumn>
                            </FlexRow>
                            <HorizontalLine />
                            <ActionRows_FlexRow
                                mainAxis="space-between"
                                fillParentWidth
                                padding={{ all: 10 }}>
                                <ButtonTransparent
                                    onClick={() => {
                                        setHour(new Date().getHours() as Hour);
                                        setMinute(
                                            new Date().getMinutes() as Minute,
                                        );
                                    }}>
                                    <TextBox
                                        text={translation({
                                            sv: "Nu",
                                            en: "Now",
                                        })}
                                    />
                                </ButtonTransparent>
                                <HorizontalDivider />
                                <ButtonPrimary onClick={actions.close}>
                                    <TextBox
                                        text={translation({
                                            sv: "Klar",
                                            en: "Ready",
                                        })}
                                    />
                                </ButtonPrimary>
                            </ActionRows_FlexRow>
                        </TimePicker_FlexColumn>
                        {/* TIDSVÄLJARE */}
                    </TimePickerContainer_FlexRow>
                )}
                trigger={actions => (
                    <FlexRow
                        crossAxis="center"
                        onClick={actions.toggle}
                        css={{
                            cursor: "pointer",
                            backgroundColor:
                                theme.colors.textInput.background.asString,
                            borderRadius: theme.radius,
                            border: `1px solid ${theme.colors.textInput.border.asString}`,
                        }}>
                        <TextBox
                            text={
                                field.value
                                    ? field.value.formatted
                                    : translation({
                                          sv: "Välj en tid",
                                          en: "Pick a time",
                                      })
                            }
                            css={{
                                flex: 1,
                                padding: 15,
                                userSelect: "text",
                            }}
                            color={
                                !field.value
                                    ? theme.colors.textInput.placeholder
                                          .asString
                                    : undefined
                            }
                        />
                        <FlexRow>
                            <Conditional when={!!field.value}>
                                <HorizontalDivider />
                                <Icon
                                    icon={faCopy}
                                    fixedWidth
                                    onClick={ev => {
                                        ev.stopPropagation();
                                        copy(field.value?.formatted ?? "");
                                        toast.info({
                                            message: translation({
                                                sv: "Tiden kopierad",
                                                en: "Time copied",
                                            }),
                                        });
                                    }}
                                    opacity={props.disabled ? 0.5 : 1}
                                />
                            </Conditional>
                            <HorizontalDivider />
                            <Icon
                                icon={faClock}
                                css={{ paddingRight: 10 }}
                                fixedWidth
                                opacity={props.disabled ? 0.5 : 1}
                            />
                        </FlexRow>
                    </FlexRow>
                )}
            />
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

const _TimePickerHeader = (props: { text: Translatable }) => {
    const theme = useTheme();
    return (
        <TextBox
            text={props.text}
            weight="700"
            css={{
                backgroundColor: theme.colors.default.background.asString,
                padding: 5,
                paddingRight: 15,
                paddingLeft: 15,
                position: "sticky",
                borderBottom: `1px solid ${theme.colors.default.border.asString}`,
                top: 0,
                zIndex: 10,
            }}
        />
    );
};

export { Form2TimePicker };
