import { useAmenitiesQuery } from "@@/backoffice/dashboard/utilities/pages/amenities/use-amenities-query";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useDefaultReactSelectStyles } from "@@/shared/form/use-default-react-select-styles";
import { useFormField } from "@@/shared/form/use-form-field";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation } from "@emotion/react";
import {
    Amenity,
    AmenityId,
    Translatable,
    emptyArrayOf,
    translation,
} from "@towni/common";
import { Draft } from "immer";
import { useMemo, useState } from "react";
import ReactSelect, { StylesConfig } from "react-select";

type PossibleValueTypes = AmenityId[] | undefined | null;
type DefaultValueType = AmenityId[] | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly initiallySelectedIds: AmenityId[];
    readonly label?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;

    // Css properties
    readonly containerCss?: Interpolation<AppTheme>;
    readonly selectStyles?: StylesConfig<
        { label: string; value: AmenityId },
        true
    >;
};

const createFormAmenityPickerComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2AmenityPicker = <
        Value extends PossibleValueTypes = DefaultValueType,
    >(
        props: Props<State, Value>,
    ) => {
        const provider = useProviderFromContext();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            formId: formId,
        });
        const [_initialValue] = useState(field.value);

        useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "automatic",
            customFieldValidationErrorMessage: translation({
                sv: "Välj minst en",
                en: "Select at least one",
            }),
        });

        const amenityQuery = useAmenitiesQuery({
            providerId: provider._id,
        });
        const amenityMap = useMemo((): Map<AmenityId, Amenity> => {
            if (!amenityQuery.data) return new Map();
            return new Map(
                amenityQuery.data.map(amenity => [amenity._id, amenity]),
            );
        }, [amenityQuery.data]);

        const translate = useTranslate();
        const options = useMemo(() => {
            if (!amenityQuery.data)
                return emptyArrayOf<{ label: string; value: AmenityId }>();
            return amenityQuery.data.map(amenity => {
                return {
                    value: amenity._id,
                    label: translate(amenity.title),
                };
            });
        }, [amenityQuery.data, translate]);

        const spin = props.spin || false;
        const disabled = props.spin || props.disabled;
        const reactSelectStyles = useDefaultReactSelectStyles<
            (typeof options)[0],
            true
        >(!!field.errors.length);

        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                fillParentWidth
                css={props.containerCss}>
                <Conditional when={!!props.label}>
                    <FieldTitle
                        text={props.label}
                        required={field.isRequired}
                    />
                    <VerticalDivider XXS />
                </Conditional>
                <VerticalDivider XXS />
                <ReactSelect
                    key={amenityQuery.isPending ? "pending" : "ready"}
                    options={options}
                    isDisabled={disabled}
                    isMulti
                    isLoading={spin}
                    menuPortalTarget={dropDownPortalTarget}
                    menuPlacement="auto"
                    formatOptionLabel={option => {
                        const amenity = amenityMap.get(option.value);
                        if (!amenity) return null;
                        return (
                            <FlexRow>
                                <DynamicIcon
                                    icon={amenity.iconName ?? "fa-question"}
                                    fixedWidth
                                />
                                <HorizontalDivider />
                                <TextBox text={amenity.title} />
                            </FlexRow>
                        );
                    }}
                    styles={
                        props.selectStyles
                            ? {
                                  ...reactSelectStyles,
                                  ...props.selectStyles,
                              }
                            : reactSelectStyles
                    }
                    defaultValue={options.filter(option =>
                        props.initiallySelectedIds.includes(option.value),
                    )}
                    onChange={(option): void => {
                        const selectedOptions = Array.from(option.values());
                        const amenityIds = selectedOptions.map(
                            item => item.value,
                        );
                        const newValue: Value = (
                            amenityIds.length
                                ? amenityIds
                                : field.isRequired
                                  ? undefined
                                  : []
                        ) as Value;

                        field.setValue(newValue);
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2AmenityPicker;
};

export { createFormAmenityPickerComponent };
