import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { NativeDatePicker } from "@@/shared/date-time/date-time-selection/native-pickers/native-date-picker";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { ModalTitle } from "@@/shared/text/modal-title";
import { use100vh } from "@@/shared/use-100-vh";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { usePrevious } from "@@/shared/use-previous";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { useToast } from "@@/toasts/context/toast-context";
import { useLocale } from "@@/translations/use-locale";
import { useTheme } from "@emotion/react";
import {
    faCalendar,
    faClock,
    faCopy,
} from "@fortawesome/pro-regular-svg-icons";
import {
    Hour,
    IsoAndUnixTimestamp,
    Minute,
    Percentage,
    Translatable,
    asDate,
    generateId,
    getMinutes,
    hours,
    isIsoTimestamp,
    isoAndUnixFactory,
    joinTranslatables,
    translation,
} from "@towni/common";
import copy from "copy-to-clipboard";
import { startOfDay } from "date-fns";
import { Draft } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DayPicker, DayPickerSingleProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Except } from "type-fest";
import { ZodSchema } from "zod";
import { FloatingBoxActions, FloatingBox } from "../floating-box";

type Value = IsoAndUnixTimestamp | undefined;

const DateTimePickerContainer_FlexColumn = FlexColumn;
const DatePicker_FlexColumn = FlexColumn;
const TimePicker_FlexColumn = FlexColumn;
const Footer_FlexRow = FlexRow;
const HourPicker_FlexColumn = FlexColumn;
const MinutePicker_FlexColumn = FlexColumn;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly datePickerProps?: Except<
        DayPickerSingleProps,
        "selected" | "onSelect" | "mode" | "disabled"
    >;

    readonly disabled?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    /** Defaults to 1. When 1 pickers shows every minute, when 5 every 5 minutes is shown in picker and so on. */
    readonly minuteOptionStep?: 1 | 5 | 10 | 15 | 20;
    /**
     * When `true` as soon as a date, hour or minute is picked `onChange` is triggered.
     * When `false`, the user has to click the "Select/Ready" button to trigger `onChange`. \
     * Defaults to `false`
     */
    readonly selectDirect?: boolean;
};

const Form2DateTimePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    // const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const theme = useTheme();
    const toast = useToast();
    const locale = useLocale();
    const [{ show, hide }, modalId] = useModal("date_time_picker_modal__");
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const { formatDateAndTimeLong, formatDateMedium } = useDateTimeFormatter();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const hasErrors = field.errors.length > 0;
    const [pickerIsOpen, setPickerIsOpen] = useState(false);

    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
        customFieldValidationErrorMessage: translation({
            sv: "Du måste välja ett datum och en tid",
            en: "You must choose a date and time",
        }),
    });

    const [selectedHour, setHour] = useState<Hour | undefined>(
        asDate(field.initialValue)?.getHours() as Hour,
    );
    const [selectedMinute, setMinute] = useState<Minute | undefined>(
        asDate(field.initialValue)?.getMinutes() as Minute,
    );

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
        const date = asDate(field.initialValue);
        return date ? startOfDay(date) : undefined;
    });

    const [result, setResult] = useState<Value | undefined>(field.value);
    const [hoursRendered, setHoursRendered] = useState<boolean>(false);
    const [minutesRendered, setMinutesRendered] = useState<boolean>(false);
    const viewHeight = use100vh();

    useUpdateEffect(() => {
        if (
            typeof selectedDate === "undefined" ||
            typeof selectedHour === "undefined" ||
            typeof selectedMinute === "undefined"
        ) {
            setResult(undefined);
            if (props.selectDirect) field.setValue(undefined);
            return;
        }
        const newDate = new Date(selectedDate);
        newDate.setHours(selectedHour);
        newDate.setMinutes(selectedMinute);
        const result = isoAndUnixFactory(newDate);
        setResult(result);
        if (props.selectDirect) field.setValue(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selectedHour, selectedMinute]);

    const hoursRefCallback = useCallback((element: HTMLDivElement) => {
        setHoursRendered(!!element);
    }, []);
    const minutesRefCallback = useCallback((element: HTMLDivElement) => {
        setMinutesRendered(!!element);
    }, []);

    const prevHoursRendered = usePrevious(hoursRendered);
    useEffect(
        function scrollHourIntoView() {
            if (!pickerIsOpen) return;
            if (!hoursRendered) return;
            const hourToScrollTo = selectedHour ?? (8 as Hour);
            const scrollBehavior = !prevHoursRendered ? "instant" : "smooth";
            const hourElement = document.getElementById(
                `hour_${hourToScrollTo}_${field.fieldId}`,
            );
            hourElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
        },
        [
            hoursRendered,
            field.fieldId,
            pickerIsOpen,
            selectedHour,
            prevHoursRendered,
        ],
    );

    const prevMinutesRendered = usePrevious(minutesRendered);
    useEffect(
        function scrollMinuteIntoView() {
            if (!pickerIsOpen) return;
            if (typeof selectedMinute === "undefined") return;
            if (!minutesRendered) return;
            const scrollBehavior = !prevMinutesRendered ? "instant" : "smooth";
            const minuteElement = document.getElementById(
                `minute_${selectedMinute}_${field.fieldId}`,
            );
            minuteElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
        },
        [
            field.fieldId,
            minutesRendered,
            pickerIsOpen,
            prevMinutesRendered,
            selectedMinute,
        ],
    );

    const componentKey = `${formId}_${props.fieldId}`;
    useEffect(() => {
        // Handle paste from clipboard
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== componentKey) return;
            const pastedText = (ev as ClipboardEvent).clipboardData?.getData(
                "Text",
            );
            if (!isIsoTimestamp(pastedText)) return;
            const datePasted = new Date(pastedText);
            setHour(datePasted.getHours() as Hour);
            setMinute(datePasted.getMinutes() as Minute);
            setSelectedDate(startOfDay(datePasted));
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [componentKey]);
    const minuteOptions = useMemo(() => {
        return getMinutes(props.minuteOptionStep);
    }, [props.minuteOptionStep]);

    const [key, setKey] = useState(generateId);
    const [defaultMonth, _setDefaultMonth] = useState<Date | undefined>(
        asDate(field.value),
    );
    const setDefaultMonth = useCallback((date: Date | undefined) => {
        _setDefaultMonth(date);
        setKey(generateId());
    }, []);

    const timePicker = (
        <TimePicker_FlexColumn
            mainAxis="stretch"
            crossAxis="stretch"
            css={{
                flex: 1,
                minWidth: "max-content",
                overflow: "hidden",
            }}>
            <FlexRow
                mainAxis="stretch"
                crossAxis="stretch"
                css={{
                    flex: 1,
                    position: "relative",
                    overflow: "hidden",
                }}>
                <HourPicker_FlexColumn
                    ref={hoursRefCallback}
                    css={[
                        {
                            flex: 1,
                            // maxWidth: 150,
                            overflowX: "hidden",
                            overflowY: "auto",
                            borderRightStyle: "solid",
                            borderRightWidth: 1,
                            borderRightColor:
                                theme.colors.default.border.asString,
                        },
                        customThinScrollbarCss,
                    ]}>
                    {/* Timmar */}
                    <_TimePickerHeader
                        text={translation({
                            sv: "Timme",
                            en: "Hour",
                        })}
                    />
                    <ForEach itemOf={hours} getKey={hour => hour}>
                        {hour => (
                            <ButtonTransparent
                                id={`hour_${hour}_${field.fieldId}`}
                                padding={{
                                    topBottom: 5,
                                    right: 15,
                                    left: 15,
                                }}
                                onClick={() => {
                                    setHour(hour);
                                }}>
                                <TextBox
                                    text={hour.toString().padStart(2, "0")}
                                    padding={{
                                        leftRight: 15,
                                        topBottom: 5,
                                    }}
                                    weight="700"
                                    css={{
                                        backgroundColor:
                                            hour === selectedHour
                                                ? theme.colors.primary.light
                                                      .asString
                                                : undefined,
                                        border:
                                            hour === selectedHour
                                                ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                : `1px solid ${theme.colors.transparent.asString}`,

                                        borderRadius: 5,
                                        color:
                                            hour === selectedHour
                                                ? theme.colors.primary
                                                      .background.asString
                                                : undefined,
                                    }}
                                />
                            </ButtonTransparent>
                        )}
                    </ForEach>
                </HourPicker_FlexColumn>
                <MinutePicker_FlexColumn
                    ref={minutesRefCallback}
                    css={[
                        {
                            flex: 1,
                            // maxWidth: 150,
                            overflowX: "hidden",
                            overflowY: "auto",
                        },
                        customThinScrollbarCss,
                    ]}>
                    {/* Minuter */}
                    <_TimePickerHeader
                        text={translation({
                            sv: "Minut",
                            en: "Minute",
                        })}
                    />
                    <ForEach itemOf={minuteOptions} getKey={minute => minute}>
                        {minute => (
                            <ButtonTransparent
                                id={`minute_${minute}_${field.fieldId}`}
                                padding={{
                                    topBottom: 5,
                                    right: 15,
                                    left: 15,
                                }}
                                onClick={() => {
                                    setMinute(minute);
                                }}>
                                <TextBox
                                    text={minute.toString().padStart(2, "0")}
                                    padding={{
                                        leftRight: 15,
                                        topBottom: 5,
                                    }}
                                    weight="700"
                                    css={{
                                        backgroundColor:
                                            minute === selectedMinute
                                                ? theme.colors.primary.light
                                                      .asString
                                                : undefined,
                                        borderRadius: 5,
                                        border:
                                            minute === selectedMinute
                                                ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                : `1px solid ${theme.colors.transparent.asString}`,
                                        color:
                                            minute === selectedMinute
                                                ? theme.colors.primary
                                                      .background.asString
                                                : undefined,
                                    }}
                                />
                            </ButtonTransparent>
                        )}
                    </ForEach>
                </MinutePicker_FlexColumn>
            </FlexRow>
        </TimePicker_FlexColumn>
    );

    const footer = (actions: FloatingBoxActions) => (
        <Footer_FlexRow fillParentWidth mainAxis="space-between">
            <HorizontalDivider />
            <ButtonPrimary
                disabled={!result}
                onClick={() => {
                    field.setValue(result);
                    actions.close();
                }}>
                <TextBox
                    text={
                        result
                            ? joinTranslatables([
                                  translation({
                                      sv: "Välj ",
                                      en: "Choose ",
                                  }),
                                  formatDateAndTimeLong(result, {
                                      includeYearIfNotCurrent: true,
                                  }),
                              ])
                            : translation({
                                  sv: "Välj datum och tid",
                                  en: "Choose a date and time",
                              })
                    }
                />
            </ButtonPrimary>
        </Footer_FlexRow>
    );

    const _props = props;
    const dateAndTimePicker = (actions: FloatingBoxActions) => (
        <DateTimePickerContainer_FlexColumn
            key={"dateAndTimePicker"}
            crossAxis="stretch"
            css={{
                backgroundColor: "white",
                borderRadius: 10,
                padding: 5,
                willChange: "filter",
                transition: "opacity 0.2s ease-in-out",
                filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                opacity: pickerIsOpen ? 1 : 0,
            }}>
            <FlexRow
                css={{ flex: 1, maxHeight: 350 }}
                mainAxis="stretch"
                crossAxis="stretch">
                <DatePicker_FlexColumn
                    css={{
                        borderRight: `1px solid ${theme.colors.default.border.asString}`,
                    }}>
                    <DayPicker
                        key={key}
                        {...(_props.datePickerProps ?? {})}
                        mode="single"
                        defaultMonth={defaultMonth}
                        disabled={_props.disabled || field.isSubmitting}
                        selected={selectedDate}
                        onDayBlur={(_date, _modifiers, _focusEvent) => {
                            field.setTouched(true);
                        }}
                        styles={{
                            row: {
                                borderTop: `1px solid ${theme.colors.transparent.asString}`,
                                borderBottom: `1px solid ${theme.colors.transparent.asString}`,
                            },
                        }}
                        modifiersStyles={{
                            selected: {
                                backgroundColor: theme.colors.primary.asString,
                                color: theme.colors.primary.text.asString,
                            },
                        }}
                        locale={locale}
                        onSelect={date => {
                            setSelectedDate(date);
                            setDefaultMonth(date);
                            validationTrigger();
                        }}
                    />
                </DatePicker_FlexColumn>
                <VerticalDivider />
                {timePicker}
            </FlexRow>
            <HorizontalLine />
            <FlexRow fillParentWidth css={{ padding: 10 }}>
                {footer(actions)}
            </FlexRow>
        </DateTimePickerContainer_FlexColumn>
    );

    const pickerField = (_actions: FloatingBoxActions) => (
        <FlexRow
            crossAxis="center"
            onClick={() => setPickerIsOpen(value => !value)}
            css={{
                cursor: "pointer",
                backgroundColor: theme.colors.textInput.background.asString,
                borderRadius: theme.radius,
                border: `1px solid ${theme.colors.textInput.border.asString}`,
            }}>
            <TextBox
                text={
                    field.value
                        ? formatDateAndTimeLong(field.value, {
                              includeYearIfNotCurrent: true,
                          })
                        : translation({
                              sv: "Välj datum och tid",
                              en: "Choose a date and time",
                          })
                }
                css={{
                    flex: 1,
                    padding: 15,
                    userSelect: "text",
                }}
                color={
                    !field.value
                        ? theme.colors.textInput.placeholder.asString
                        : undefined
                }
            />
            <FlexRow>
                <Conditional when={!!field.value}>
                    <HorizontalDivider />
                    <Icon
                        icon={faCopy}
                        fixedWidth
                        onClick={ev => {
                            ev.stopPropagation();
                            copy(field.value?.iso ?? "");
                            toast.info({
                                message: translation({
                                    sv: "Tiden kopierad i ISO8601 format",
                                    en: "Time copied in ISO8601 format",
                                }),
                            });
                        }}
                        opacity={props.disabled ? 0.5 : 1}
                    />
                </Conditional>
                <HorizontalDivider />
                <Icon
                    icon={faClock}
                    css={{ paddingRight: 10 }}
                    fixedWidth
                    opacity={props.disabled ? 0.5 : 1}
                />
            </FlexRow>
        </FlexRow>
    );

    const picker = isSmallScreen ? (
        <>
            {pickerField}
            <Modal
                modalId={modalId}
                onHide={() => {
                    setDefaultMonth(asDate(field.value));
                }}
                height={
                    viewHeight > 700 ? (0.7 as Percentage) : (0.8 as Percentage)
                }
                header={
                    <ModalHeader
                        modalId={modalId}
                        bottomBorder
                        topRightButton={
                            result
                                ? {
                                      _type: "TOP_RIGHT_ACTION_TEXT",
                                      text: translation({
                                          sv: "Klar",
                                          en: "Ready",
                                      }),
                                      action: () => {
                                          field.setValue(result);
                                          setPickerIsOpen(false);
                                      },
                                  }
                                : undefined
                        }
                        title={
                            <ModalTitle
                                text={translation({
                                    sv: "Välj ett datum",
                                    en: "Pick a date",
                                })}
                            />
                        }
                    />
                }>
                <FlexColumn
                    fillParentWidth
                    css={{ flex: 1, overflow: "hidden", paddingTop: 20 }}>
                    <FlexColumn
                        fillParentWidth
                        crossAxis="center"
                        css={{
                            flex: 1,
                            overflow: "hidden",
                            paddingLeft: 20,
                            paddingRight: 20,
                        }}>
                        <NativeDatePicker
                            selectedDate={selectedDate}
                            css={{
                                width: "100%",
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                            onChange={selected => {
                                setSelectedDate(asDate(selected));
                                validationTrigger();
                            }}>
                            <FlexColumn
                                fillParentWidth
                                mainAxis="flex-start"
                                css={{ flex: 1 }}>
                                <FieldTitle
                                    text={translation({
                                        sv: "Välj ett datum",
                                        en: "Pick a date",
                                    })}
                                    required={field.isRequired}
                                />
                                <VerticalDivider XXS />
                                <FlexRow
                                    fillParentWidth
                                    crossAxis="center"
                                    onClick={() =>
                                        setPickerIsOpen(value => !value)
                                    }
                                    css={{
                                        cursor: "pointer",
                                        backgroundColor:
                                            theme.colors.textInput.background
                                                .asString,
                                        borderRadius: theme.radius,
                                        border: `1px solid ${theme.colors.textInput.border.asString}`,
                                    }}>
                                    <Icon
                                        icon={faCalendar}
                                        opacity={0.6}
                                        color={theme.colors.textInput.text}
                                        css={{
                                            paddingLeft: 15,
                                        }}
                                    />
                                    <TextBox
                                        text={
                                            selectedDate
                                                ? formatDateMedium(
                                                      isoAndUnixFactory(
                                                          selectedDate,
                                                      ),
                                                      {
                                                          includeYearIfNotCurrent:
                                                              true,
                                                      },
                                                  )
                                                : translation({
                                                      sv: "Välj ett datum",
                                                      en: "Pick a date",
                                                  })
                                        }
                                        css={{
                                            flex: 1,
                                            padding: 15,
                                            userSelect: "text",
                                        }}
                                        color={
                                            !field.value
                                                ? theme.colors.textInput
                                                      .placeholder.asString
                                                : undefined
                                        }
                                    />
                                </FlexRow>
                            </FlexColumn>
                        </NativeDatePicker>
                        <VerticalDivider M />
                        <FlexColumn
                            fillParentWidth
                            crossAxis="stretch"
                            css={{
                                flex: 1,
                                overflow: "hidden",
                            }}>
                            <FieldTitle
                                text={translation({
                                    sv: "Välj en tid",
                                    en: "Pick a time",
                                })}
                                required={field.isRequired}
                            />
                            <VerticalDivider XXS />
                            <FlexColumn
                                fillParentWidth
                                mainAxis="stretch"
                                css={{
                                    flex: 1,
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor:
                                        theme.colors.default.border.asString,
                                    borderRadius: theme.radius,
                                    minHeight: 200,
                                    overflow: "hidden",
                                }}>
                                {timePicker}
                            </FlexColumn>
                        </FlexColumn>
                        <VerticalDivider M />
                    </FlexColumn>
                    <HorizontalLine size={1} css={{ width: "100%" }} />
                    <FlexRow fillParentWidth css={{ padding: 20 }}>
                        {footer({
                            close: () => setPickerIsOpen(false),
                            open: () => setPickerIsOpen(true),
                            toggle: () => setPickerIsOpen(value => !value),
                        })}
                    </FlexRow>
                </FlexColumn>
            </Modal>
        </>
    ) : (
        <FloatingBox
            content={dateAndTimePicker}
            trigger={pickerField}
            onOpen={() => {
                setPickerIsOpen(true);
            }}
            onClose={() => {
                setPickerIsOpen(false);
                setDefaultMonth(asDate(field.value));
            }}
        />
    );
    useEffect(() => {
        if (!isSmallScreen) return;
        if (pickerIsOpen) show();
        else hide();
    }, [isSmallScreen, pickerIsOpen, show, hide]);

    return (
        <FlexColumn
            id={componentKey}
            key={componentKey}
            tabIndex={0}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="700"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            {picker}
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

const _TimePickerHeader = (props: { text: Translatable }) => {
    const theme = useTheme();
    return (
        <TextBox
            text={props.text}
            weight="700"
            css={{
                backgroundColor: theme.colors.default.background.asString,
                padding: 5,
                paddingRight: 15,
                paddingLeft: 15,
                position: "sticky",
                borderBottom: `1px solid ${theme.colors.default.border.asString}`,
                top: 0,
                zIndex: 10,
            }}
        />
    );
};

export { Form2DateTimePicker };
