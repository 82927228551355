import {
    DateRangePreset,
    useDefaultPastTimeRangePresets,
} from "@@/backoffice/for-providers/reports/transactions/transaction-date-presets";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useLocale } from "@@/translations/use-locale";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faCalendar, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
    DateRange,
    Translatable,
    asDate,
    dateRangeFactory,
    generateId,
    isoAndUnixFactory,
    startOfDayZoned,
    translation,
} from "@towni/common";
import { isWithinInterval } from "date-fns";
import { Draft } from "immer";
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useTransition,
} from "react";
import { DayPicker, type DayPickerRangeProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Select from "react-select";
import { Except } from "type-fest";
import { ZodSchema } from "zod";
import { type FloatingBoxActions, FloatingBox } from "../floating-box";
import { ButtonTransparent } from "../buttons_v2/button-gray";

type Value = DateRange | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    /** Defaults to true */
    readonly compact?: boolean;
    readonly disabled?: boolean;
    readonly customPresets?: DateRangePreset[];
    readonly datePickerProps?: Except<
        DayPickerRangeProps,
        "selected" | "onSelect" | "mode" | "defaultMonth" | "disabled"
    >;
    readonly align?: "left" | "center";
};

const Form2DateRangePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const [{ show, hide }, modalId] = useModal("day_picker_range_modal__");
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const isCompact = props.compact ?? true;
    const locale = useLocale();
    const translate = useTranslate();
    const [__, startTransition] = useTransition();

    const defaultPresets = useDefaultPastTimeRangePresets();
    const presets = props.customPresets ?? defaultPresets;
    const presetOptions = useMemo(() => {
        return presets.map(preset => ({
            label: translate(preset.name),
            value: [
                isoAndUnixFactory(preset.from),
                isoAndUnixFactory(preset.to),
            ] as const,
        }));
    }, [presets, translate]);

    const { formatDateMedium } = useDateTimeFormatter();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const presetOptionSelected = useMemo(() => {
        const result = presetOptions.find(option => {
            return (
                field.value?.start.iso === option.value[0].iso &&
                field.value.end.iso ===
                    isoAndUnixFactory(
                        startOfDayZoned(
                            asDate(option.value[1]),
                            currentTimezoneName,
                        ),
                    ).iso
            );
        });
        return result;
    }, [presetOptions, field.value]);

    const [key, setKey] = useState(generateId);
    const [defaultMonth, _setDefaultMonth] = useState<Date | undefined>(
        asDate(field.value?.start),
    );
    const setDefaultMonth = useCallback((date: Date | undefined) => {
        _setDefaultMonth(date);
        setKey(generateId());
    }, []);

    const hasErrors = field.errors.length > 0;
    const [pickerIsOpen, setPickerIsOpen] = useState(false);
    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });
    const rangeAsText = field.value
        ? `${formatDateMedium(field.value.start, {
              includeYearIfNotCurrent: true,
          })}${
              field.value.start.iso !== field.value.end?.iso
                  ? ` - ${formatDateMedium(field.value.end, {
                        includeYearIfNotCurrent: true,
                    })}`
                  : ""
          }`
        : undefined;
    const _props = props;
    const innerDayPicker = (actions: FloatingBoxActions) => (
        <FlexColumn fillParentWidth>
            <Conditional when={!isSmallScreen}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="center"
                    css={{
                        backgroundColor:
                            theme.colors.default.background.light97.asString,
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Välj ett datumspann",
                            en: "Choose a date range",
                        })}
                        size={1.2}
                        weight="700"
                        css={{
                            flex: 1,
                            padding: 20,
                        }}
                    />

                    <HorizontalLine />
                    <ButtonTransparent onClick={actions.close}>
                        <Icon icon={faTimes} fixedWidth />
                    </ButtonTransparent>
                </FlexRow>
            </Conditional>
            <FlexRow fillParentWidth mainAxis="center" crossAxis="center">
                <DayPicker
                    key={`${defaultMonth?.toISOString()}_${key}`}
                    {...(_props.datePickerProps ?? {})}
                    mode="range"
                    disabled={_props.disabled || field.isSubmitting}
                    defaultMonth={defaultMonth}
                    selected={
                        field.value
                            ? {
                                  from: asDate(field.value.start),
                                  to:
                                      field.value.end === field.value.start
                                          ? undefined
                                          : asDate(field.value.end),
                              }
                            : undefined
                    }
                    onDayBlur={(_date, _modifiers, _focusEvent) => {
                        field.setTouched(true);
                        validationTrigger();
                    }}
                    styles={{
                        row: {
                            borderTop: `1px solid ${theme.colors.transparent.asString}`,
                            borderBottom: `1px solid ${theme.colors.transparent.asString}`,
                        },
                    }}
                    modifiersStyles={{
                        // selected: {
                        //     backgroundColor: theme.colors.primary.asString,
                        //     color: theme.colors.primary.text.asString,
                        // },
                        range_start: {
                            backgroundColor: theme.colors.primary.asString,
                            color: theme.colors.primary.text.asString,
                            fontWeight: 400,
                        },
                        range_end: {
                            backgroundColor: theme.colors.primary.asString,
                            color: theme.colors.primary.text.asString,
                            fontWeight: 400,
                        },
                        range_middle: {
                            backgroundColor: theme.colors.primary.asString,
                            color: theme.colors.primary.text.asString,
                            fontWeight: 400,
                        },

                        today: {
                            fontWeight: 900,
                            backgroundColor:
                                theme.colors.primary.light.asString,
                            color: theme.colors.primary.asString,
                        },
                    }}
                    locale={locale}
                    onSelect={(range, selectedDate, _activeModifiers) => {
                        const prevValue = field.value;
                        const dateRange = (() => {
                            if (
                                prevValue &&
                                prevValue.start.iso ===
                                    isoAndUnixFactory(selectedDate).iso &&
                                prevValue.start.iso !== prevValue.end.iso
                            ) {
                                return dateRangeFactory(selectedDate);
                            }
                            if (range?.from && !range.to) {
                                return dateRangeFactory(
                                    range.from,
                                    selectedDate,
                                );
                            }
                            if (
                                prevValue &&
                                prevValue.start.iso === prevValue.end.iso &&
                                range?.from &&
                                range.to
                            ) {
                                return dateRangeFactory(range.from, range.to);
                            }
                            if (
                                prevValue &&
                                prevValue.start.iso !== prevValue.end.iso &&
                                !isWithinInterval(selectedDate, {
                                    start: asDate(prevValue.start),
                                    end: asDate(prevValue.end),
                                })
                            ) {
                                return dateRangeFactory(selectedDate);
                            }
                            if (_props.datePickerProps?.min) {
                                if (!range?.to) {
                                    return undefined;
                                }
                            }
                            if (range?.from && range.to) {
                                return dateRangeFactory(range.from, range.to);
                            }
                            if (range?.from && !range.to) {
                                return dateRangeFactory(range.from, range.from);
                            }
                            return undefined;
                        })();
                        field.setValue(dateRange);
                        field.setDirty(true);
                        field.setTouched(true);
                    }}
                />
            </FlexRow>
            <FlexRow
                css={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                <Select
                    key={presetOptionSelected?.value?.join(">")}
                    options={presetOptions}
                    isDisabled={!presetOptions.length}
                    styles={{
                        container: current => ({
                            ...current,
                            flex: 1,
                            opacity: presetOptions.length ? 1 : 0.5,
                            // width: "100%",
                        }),
                        control: (current, state) => ({
                            ...current,
                            borderRadius: 6,
                            paddingTop: 1,
                            paddingBottom: 2,
                            // width: 100,
                            flex: 1,
                            border: `1px solid ${
                                state.isFocused
                                    ? theme.colors.textInput.border.asString
                                    : theme.colors.textInput.border.asString
                            }`,
                            backgroundColor:
                                theme.colors.textInput.background.asString,
                            boxShadow: "none",
                            "&:hover": {
                                border: `1px solid ${theme.colors.black.light80.asString}`,
                            },
                        }),
                        placeholder: current => ({
                            ...current,
                            color: theme.colors.textInput.placeholder.asString,
                        }),
                    }}
                    placeholder={translate(
                        translation({
                            sv: "Förval",
                            en: "Presets",
                        }),
                    )}
                    value={presetOptionSelected}
                    menuPortalTarget={dropDownPortalTarget}
                    menuPlacement="auto"
                    onChange={selection => {
                        startTransition(() => {
                            if (!selection?.value) return;
                            const startDate = asDate(selection.value[0]);
                            const endDate = startOfDayZoned(
                                asDate(selection.value[1]),
                                currentTimezoneName,
                            );
                            const value = dateRangeFactory(startDate, endDate);
                            field.setValue(value);
                            setDefaultMonth(startDate);
                        });
                    }}
                />
            </FlexRow>
            <HorizontalLine />
            <FlexRow
                fillParentWidth
                mainAxis={isCompact ? "stretch" : "space-between"}
                crossAxis="stretch"
                css={{
                    flex: 1,
                    padding: 10,
                    paddingBottom: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}>
                <Conditional
                    when={!!rangeAsText}
                    else={() => <Spacer css={{ flex: 1 }} />}>
                    <FlexColumn css={{ flex: 1 }}>
                        <TextBox
                            text={translation({
                                sv: "Valt",
                                en: "Selected",
                            })}
                            case="UPPERCASE"
                            css={{
                                fontSize: "0.625rem",
                                fontWeight: 900,
                                padding: 5,
                                color: theme.colors.black.light70.asString,
                                width: "fit-content",
                            }}
                        />
                        <TextBox
                            text={rangeAsText}
                            size={0.825}
                            title={translation({
                                sv: "Hoppa till början på valt datum/datumspann i kalendern",
                                en: "Jump to the beginning of the selected date/date range in the calendar",
                            })}
                            onClick={() => {
                                setDefaultMonth(asDate(field.value?.start));
                            }}
                            css={{
                                width: "fit-content",
                                cursor: "pointer",
                                borderRadius: 5,
                                padding: "5px 10px",
                                backgroundColor:
                                    theme.colors.black.light97.asString,
                            }}
                        />
                    </FlexColumn>
                    <HorizontalDivider M />
                </Conditional>

                <Conditional when={isCompact}>
                    <ButtonPrimaryLight
                        padding={{ leftRight: 20, topBottom: 10 }}
                        onClick={actions.close}>
                        <TextBox
                            text={translate(
                                translation({
                                    sv: "Klar",
                                    en: "Ready",
                                }),
                            )}
                        />
                    </ButtonPrimaryLight>
                </Conditional>
            </FlexRow>
        </FlexColumn>
    );

    const pickerField = (actions: FloatingBoxActions) => (
        <FlexRow
            className={props.className}
            mainAxis={props.align === "center" ? "center" : "flex-start"}
            crossAxis="center"
            onClick={actions.toggle}
            css={{
                cursor: "pointer",
                backgroundColor: theme.colors.textInput.background.asString,
                borderRadius: theme.radius,
                border: `1px solid ${theme.colors.textInput.border.asString}`,
            }}>
            <TextBox
                text={
                    rangeAsText ??
                    translation({
                        sv: "Välj ett datumspann",
                        en: "Choose a date range",
                    })
                }
                align={props.align ?? "left"}
                css={{
                    flex: 1,
                    padding: 15,
                }}
                color={
                    !field.value
                        ? theme.colors.textInput.placeholder.asString
                        : undefined
                }
            />
            <HorizontalDivider />
            <Icon
                icon={faCalendar}
                css={{ paddingRight: 15 }}
                fixedWidth
                opacity={props.disabled ? 0.5 : 1}
            />
        </FlexRow>
    );

    useEffect(() => {
        if (!isSmallScreen) return;
        if (pickerIsOpen) show();
        else hide();
    }, [isSmallScreen, pickerIsOpen, show, hide]);

    const picker = isSmallScreen ? (
        <>
            {pickerField}
            <Modal
                modalId={modalId}
                onHide={() => {
                    setDefaultMonth(asDate(field.value?.start));
                }}
                header={
                    <ModalHeader
                        modalId={modalId}
                        bottomBorder
                        title={
                            <ModalTitle
                                text={translation({
                                    sv: "Välj ett datumspann",
                                    en: "Choose a date range",
                                })}
                            />
                        }
                    />
                }>
                <FlexColumn
                    fillParentWidth
                    crossAxis="center"
                    mainAxis="center">
                    {innerDayPicker({
                        close: () => setPickerIsOpen(false),
                        open: () => setPickerIsOpen(true),
                        toggle: function (): void {
                            setPickerIsOpen(prev => !prev);
                        },
                    })}
                </FlexColumn>
            </Modal>
        </>
    ) : (
        <FloatingBox
            content={innerDayPicker}
            trigger={pickerField}
            onClose={() => {
                setDefaultMonth(asDate(field.value?.start));
            }}
        />
    );

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="400"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            {!isCompact ? (
                <div
                    css={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.colors.textInput.border.asString,
                    }}>
                    {innerDayPicker({
                        close: () => setPickerIsOpen(false),
                        open: () => setPickerIsOpen(true),
                        toggle: function (): void {
                            setPickerIsOpen(prev => !prev);
                        },
                    })}
                </div>
            ) : (
                <>{picker}</>
            )}
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2DateRangePicker };
