import { Conditional } from "@@/shared/conditional";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { ColorItem, Translatable } from "@towni/common";

type Props = {
    readonly backgroundColor?: ColorItem;
    readonly color?: ColorItem;
    readonly subtitleColor?: ColorItem;
    readonly title: Translatable;
    readonly subtitle?: Translatable;
};

const TicketCustomerViewStatus = (props: Props) => {
    const theme = useTheme();
    return (
        <FlexColumn
            fillParentWidth
            padding={{ leftRight: 38 }}
            mainAxis="center"
            crossAxis="center"
            css={{
                position: "absolute",
                zIndex: 11,
                //bottom: 50,
                top: 100,
                maxWidth: 310,
            }}>
            <FlexColumn
                fillParentWidth
                crossAxis="center"
                background={{
                    color: props.backgroundColor || theme.colors.white,
                }}
                padding={{ all: 16 }}
                radius={10}>
                <TextBox
                    text={props.title}
                    color={props.color || theme.colors.black}
                    size={0.875}
                    weight="900"
                />
                <Conditional when={!!props.subtitle}>
                    <TextBox
                        text={props.subtitle}
                        padding={{ top: 5, leftRight: 20 }}
                        css={{ textAlign: "center" }}
                        color={props.subtitleColor || theme.colors.black}
                        size={"XS"}
                        weight="400"
                    />
                </Conditional>
            </FlexColumn>
        </FlexColumn>
    );
};

export { TicketCustomerViewStatus };
