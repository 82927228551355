import { browserLogger } from "@@/settings";
import { emptyArrayOf, generateComboId, SocketEvent } from "@towni/common";
import * as React from "react";
import { WebSocketContext } from "./socket-context";

type SocketListener<AnEvent extends SocketEvent = SocketEvent> = {
    readonly eventType: AnEvent["type"];
    readonly room?: string;
    readonly onEvent: (eventData: AnEvent, room: string | undefined) => void;
};

const createSocketEventListener = <AnEvent extends SocketEvent>(params: {
    readonly eventType: AnEvent["type"];
    readonly room?: string;
    readonly onEvent: (eventData: AnEvent, room: string | undefined) => void;
}): SocketListener<AnEvent> => {
    return {
        eventType: params.eventType,
        onEvent: params.onEvent,
        room: params.room,
    } as SocketListener<AnEvent>;
};

const createSocketEventListeners = <AnEvent extends SocketEvent>(params: {
    readonly eventTypes: AnEvent["type"][];
    readonly rooms?: string[];
    readonly onEvent: (eventData: AnEvent, room: string | undefined) => void;
}): SocketListener<AnEvent>[] => {
    return params.eventTypes.flatMap(type =>
        params.rooms
            ? params.rooms.map(
                  room =>
                      ({
                          eventType: type,
                          onEvent: params.onEvent,
                          room,
                      }) as SocketListener<AnEvent>,
              )
            : ({
                  eventType: type,
                  onEvent: params.onEvent,
              } as SocketListener<AnEvent>),
    );
};

const useSocket = <AnEvent extends SocketEvent>(
    listenFor: SocketListener<AnEvent>[] = emptyArrayOf<
        SocketListener<AnEvent>
    >(),
    options?: { verbose?: boolean },
) => {
    const {
        socket,
        connected: socketConnected,
        localConnectionId: localSocketConnectionId,
    } = React.useContext(WebSocketContext);
    const registrationId = React.useMemo(() => {
        const newId = generateComboId();
        browserLogger.info("🔌 ws generate registrationId:", {
            newId,
        });
        return newId;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, localSocketConnectionId, socketConnected]);

    React.useEffect(() => {
        if (
            !socket ||
            !socketConnected ||
            !localSocketConnectionId ||
            !listenFor.length
        ) {
            browserLogger.log("🔌 ws no socket or listenFor", {
                socket,
                socketConnected,
                localSocketConnectionId,
                listenFor,
                registrationId,
            });
            return;
        }

        const registration = {
            regId: registrationId,
            rooms: Array.from(
                new Set<string>(
                    listenFor
                        .map(item => item.room)
                        .filter(Boolean) as unknown as string[],
                ),
            ),
        };

        const listeners: (readonly [
            (typeof listenFor)[number]["eventType"],
            (
                data: Parameters<(typeof listenFor)[number]["onEvent"]>[0],
                room: Parameters<(typeof listenFor)[number]["onEvent"]>[1],
            ) => void,
            (typeof listenFor)[number]["room"],
        ])[] = [];

        const onConnect = () => {
            browserLogger.log("🔌 ws (re)connect");
            listeners.forEach(([eventType, listener]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                socket.off(eventType, listener as any);
            });
            socket.emit("register", registration);
        };
        const onRegistered = (data: { regId: string; rooms: string[] }) => {
            if (registration.regId !== data.regId) {
                browserLogger.log("🔌 ws registered mismatch", {
                    registrationId,
                    data,
                });
                return;
            }
            if (data.regId !== registrationId) {
                browserLogger.log("🔌 ws registered mismatch", {
                    registrationId,
                    data,
                });
                return;
            }
            browserLogger.log("🔌 ws registered " + registrationId);
            data.rooms.length &&
                browserLogger.log("🔌 ws joined " + data.rooms.join(","));

            // Register listeners
            listeners.length = 0;
            listenFor
                .map(
                    listener =>
                        [
                            listener.eventType,
                            (
                                event: Parameters<typeof listener.onEvent>[0],
                                room: string | string[] | undefined,
                            ) => {
                                // todo, verify data is of the right type
                                browserLogger.log(
                                    "🥳 socket",
                                    event,
                                    room,
                                    listener.room,
                                    !room && !listener.room,
                                    room && listener.room && Array.isArray(room)
                                        ? room.includes(listener.room)
                                        : room === listener.room,
                                    listener.eventType,
                                );
                                const roomMatch =
                                    (!room && !listener.room) ||
                                    (room &&
                                    listener.room &&
                                    Array.isArray(room)
                                        ? room.includes(listener.room)
                                        : room === listener.room);

                                if (
                                    roomMatch &&
                                    event.type === listener.eventType
                                ) {
                                    listener.onEvent(event, listener.room);
                                }
                            },
                            listener.room,
                        ] as const,
                )
                .forEach(listener => listeners.push(listener));

            listeners.forEach(([eventType, listener, _room]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                socket.on(eventType, listener as unknown as any); // todo, fix typing
                // console.log(
                //     "🔌 ws listening for " +
                //         eventType +
                //         (room ? "; room " + room : "")
                // );
            });
        };

        // Register
        browserLogger.log("🔌 ws emit register", socket?.connected); // listenFor
        socket.emit("register", registration);
        socket.on("connect", onConnect);
        socket.on("reconnect", onConnect);
        socket.on("registered", onRegistered);

        const verboseLogging = (...args: unknown[]) => {
            browserLogger.log("[verbose] 🔌 ws " + socket.id, ...args);
        };
        if (options?.verbose) socket.onAny(verboseLogging);

        return () => {
            browserLogger.log("🔌 ws unregister to events", { registrationId });
            socket.off("connect", onConnect);
            socket.off("reconnect", onConnect);
            socket.off("registered", onRegistered);
            socket.offAny(verboseLogging);
            listeners.forEach(([eventType, listener]) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                socket.off(eventType, listener as any);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationId]);

    return [socketConnected];
};

export { createSocketEventListener, createSocketEventListeners, useSocket };
export type { SocketListener };
