import {
    autoPlacement,
    autoUpdate,
    FloatingPortal,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTheme } from "@emotion/react";

type FloatingBoxActions = {
    readonly close: () => void;
    readonly open: () => void;
    readonly toggle: () => void;
};

const FloatingBox = (props: {
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
    readonly trigger: (actions: FloatingBoxActions) => JSX.Element;
    readonly content: (actions: FloatingBoxActions) => JSX.Element;
}) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const close = useCallback(() => setIsOpen(false), [setIsOpen]);
    const open = useCallback(() => setIsOpen(true), [setIsOpen]);
    const toggle = useCallback(
        (value?: boolean) => setIsOpen(prev => value ?? !prev),
        [setIsOpen],
    );
    const actions = useMemo(
        () => ({ close, open, toggle }),
        [close, open, toggle],
    );

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            shift({
                crossAxis: true,
                mainAxis: true,
            }),
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
        ],
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
    ]);

    const onOpenRef = useRef(props.onOpen);
    const onCloseRef = useRef(props.onClose);
    onOpenRef.current = props.onOpen;
    onCloseRef.current = props.onClose;

    useEffect(() => {
        if (isOpen) onOpenRef.current?.();
        else onCloseRef.current?.();
    }, [isOpen]);

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {props.trigger(actions)}
            </div>
            {!isOpen ? null : (
                <FloatingPortal>
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                        css={{
                            backgroundColor: "white",
                            borderRadius: 10,
                            willChange: "filter",
                            transition: "opacity 0.2s ease-in-out",
                            filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                            zIndex: 33,
                        }}>
                        {props.content(actions)}
                    </div>
                </FloatingPortal>
            )}
        </>
    );
};

export { FloatingBox };
export type { FloatingBoxActions };
