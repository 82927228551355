import { useAccommodationTypesQuery } from "@@/backoffice/dashboard/utilities/pages/accommodation-types/use-accommodation-types-query";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useDefaultReactSelectStyles } from "@@/shared/form/use-default-react-select-styles";
import { useFormField } from "@@/shared/form/use-form-field";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation } from "@emotion/react";
import {
    AccommodationType,
    AccommodationTypeId,
    Translatable,
    emptyArrayOf,
    unique,
} from "@towni/common";
import { Draft } from "immer";
import { useMemo, useState } from "react";
import ReactSelect, { StylesConfig } from "react-select";

type PossibleValueTypes = AccommodationTypeId[] | undefined;

type Props<State, Value> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;

    readonly initiallySelectedIds?: AccommodationTypeId[];
    readonly label?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;

    // Css properties
    readonly containerCss?: Interpolation<AppTheme>;
    readonly selectStyles?: StylesConfig<
        { label: string; value: AccommodationTypeId },
        false
    >;
};

const createFormAccommodationTypePickersComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2AccommodationTypePickers = <Value extends PossibleValueTypes>(
        props: Props<State, Value>,
    ) => {
        const provider = useProviderFromContext();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            formId: formId,
        });

        const [_initialValue] = useState(field.value);

        useFormFieldValidation<State, Value>({
            field,
            initialValidationType: "automatic",
        });

        const accommodationTypeQuery = useAccommodationTypesQuery({
            providerId: provider._id,
        });
        const accommodationTypeMap = useMemo((): Map<
            AccommodationTypeId,
            AccommodationType
        > => {
            if (!accommodationTypeQuery.data) return new Map();
            return new Map(
                accommodationTypeQuery.data.map(accommodationType => [
                    accommodationType._id,
                    accommodationType,
                ]),
            );
        }, [accommodationTypeQuery.data]);

        const translate = useTranslate();
        const options = useMemo(() => {
            if (!accommodationTypeQuery.data)
                return emptyArrayOf<{
                    label: string;
                    value: AccommodationTypeId;
                }>();
            return accommodationTypeQuery.data.map(amenity => {
                return {
                    value: amenity._id,
                    label: translate(amenity.title),
                };
            });
        }, [accommodationTypeQuery.data, translate]);

        const spin = props.spin || false;
        const disabled = props.spin || props.disabled;
        const reactSelectStyles = useDefaultReactSelectStyles<
            (typeof options)[0],
            false
        >(!!field.errors.length);
        return (
            <FlexColumn
                key={`${formId}_${props.fieldId}`}
                fillParentWidth
                css={props.containerCss}>
                <Conditional when={!!props.label}>
                    <FieldTitle
                        text={props.label}
                        required={field.isRequired}
                    />
                    <VerticalDivider XS />
                </Conditional>
                <ReactSelect
                    key={accommodationTypeQuery.isPending ? "pending" : "ready"}
                    options={options}
                    isDisabled={disabled}
                    isLoading={spin}
                    menuPortalTarget={dropDownPortalTarget}
                    menuPlacement="auto"
                    styles={
                        props.selectStyles
                            ? {
                                  ...reactSelectStyles,
                                  ...props.selectStyles,
                              }
                            : reactSelectStyles
                    }
                    value={options.filter(
                        item =>
                            !!field.value &&
                            field.value.some(
                                selectedId => selectedId === item.value,
                            ),
                    )}
                    defaultValue={options.filter(
                        item =>
                            !!props.initiallySelectedIds &&
                            props.initiallySelectedIds.some(
                                selectedId => selectedId === item.value,
                            ),
                    )}
                    formatOptionLabel={option => {
                        const accommodationType =
                            typeof option.value === "string"
                                ? accommodationTypeMap.get(option.value)
                                : undefined;
                        if (!accommodationType) return null;
                        return (
                            <FlexRow>
                                <DynamicIcon
                                    icon={
                                        accommodationType.iconName ??
                                        "fa-question"
                                    }
                                    fixedWidth
                                />
                                <HorizontalDivider />
                                <TextBox text={accommodationType.title} />
                            </FlexRow>
                        );
                    }}
                    isMulti={true}
                    onChange={(option): void => {
                        if (!option) {
                            field.setValue(
                                emptyArrayOf<AccommodationTypeId>() as Value,
                            );
                            return;
                        }
                        if (!Array.isArray(option)) {
                            return;
                        }

                        const selectedIds =
                            option?.map(
                                item => item.value as AccommodationTypeId,
                            ) ?? [];
                        field.setValue(unique(selectedIds) as Value);
                    }}
                />
                <FormErrorMessages errors={field.errors} />
            </FlexColumn>
        );
    };
    return Form2AccommodationTypePickers;
};
export { createFormAccommodationTypePickersComponent };
